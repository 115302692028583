<template>
  <div class="admin-dashboard-page custom-container">
    <div class="dashboard-container">
      <div class="header-wrapper">
        <h4 class="title">
          DASHBOARD
        </h4>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Search"
            debounce="200"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              <icon-search />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <b-row>
        <b-col md="6">
          <div class="active-flights">
            <div class="title-wrapper">
              <h5 class="title">
                Aktiv Flights
              </h5>
            </div>
            <div class="summary-wrapper">
              <div class="summary-item">
                <p class="label">
                  Running Flights
                </p>
                <p class="data">
                  {{ activeGamesOverview.totalFlights }}
                </p>
              </div>
              <div class="summary-item">
                <p class="label">
                  Gesamtspieler
                </p>
                <p class="data">
                  {{ getTotalPlayers(activeGamesOverview.totalMembers) }}
                </p>
              </div>
            </div>
            <div class="table-box">
              <b-table
                :fields="activeFlightsTableFields"
                :items="sortedActiveGamesOverviewData"
                :filter="filter"
                :filter-function="dashboardFilterTable"
                :busy="isLoading"
                bordered
                responsive
                :tbody-tr-class="rowClass"
                hover
                show-empty
                sticky-header="421px"
              >
                <template v-slot:cell(teamSize)="{ item }">
                  {{ activeGamesOverview.totalMembers[item['game_id']] }}
                </template>
                <template v-slot:cell(scored_datetime)="{ value }">
                  {{ getTimeFromDatetime(value) }}
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div class="today-booking">
            <div class="title-wrapper">
              <h5 class="title">
                Buchungen Heute
              </h5>
            </div>
            <div class="table-box">
              <b-table
                :fields="todayBookingTableFields"
                :items="filteredEvents"
                :filter="filter"
                :filter-function="dashboardFilterTable"
                :busy="isLoading"
                bordered
                responsive
                striped
                hover
                show-empty
                sticky-header="421px"
              >
                <template v-slot:cell(teamSize)="{ value }">
                  {{ getAdventureGolfTeamSizeSum(value) }}
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-dashboard.scss";
</style>

<script>
import IconSearch from '@/components/icons/IconSearch';
import { activeFlightsTableFields, todayBookingTableFields } from './config.js';
import { mapState } from 'vuex';
require('@/plugins/datejs/date-de-DE');
import dayjs from 'dayjs';

export default {
  components: {
    IconSearch,
  },
  data() {
    return {
      timer: '',
      filter: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      activeGamesOverview: (state) => state.admin.activeGamesOverview,
      events: (state) => state.admin.events,
    }),
    activeFlightsTableFields() {
      return activeFlightsTableFields;
    },
    todayBookingTableFields() {
      return todayBookingTableFields;
    },
    sortedActiveGamesOverviewData() {
      if (!this.activeGamesOverview.data) {
        return [];
      }
      let data = [...this.activeGamesOverview.data];
      let result = data.sort((a,b) => parseInt(a.hole) - parseInt(b.hole));
      return result;
    },
    filteredEvents() {
      return this.events.filter((item) => {
        return item.activityName === 'Adventure Golf' || item.activityName === 'Golf Room';
      });
    },
    activeGamesHoles() {
      let holes = this.activeGamesOverview.data.map((item) => item.hole);
      return holes.reduce((acc, cur) => {
        return acc.includes(cur) ? acc : acc.concat(cur);
      }, []);
    },
    evenHoles() {
      let result = this.activeGamesHoles.filter((item, index) => {
        if (parseInt(index) % 2 !== 0) {
          return item;
        }
      });
      return result;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.timer = setInterval(() => {
      this.fetchData();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    fetchData() {
      const today = Date.today().toString('yyyy-MM-dd');
      const tomorrow = Date.today().addDays(1).toString('yyyy-MM-dd');
      this.$store.dispatch('admin/activeGamesOverview');
      this.$store.dispatch('admin/showAllEvents', {
        dateFrom: today,
        dateTo: tomorrow,
      });
    },
    getTotalPlayers(totalMembers) {
      if (totalMembers && totalMembers !== 0) {
        let teamMembersValueArr = Object.values(totalMembers).map((item) => parseInt(item));
        let sum = teamMembersValueArr.reduce((acc, cur) => {
          return acc + cur;
        }, 0);
        return sum.toString();
      } else {
        return totalMembers || 0;
      }
    },
    getAdventureGolfTeamSizeSum(sizeString) {
      if (!sizeString) {
        return '';
      }
      const sizes = sizeString.split('|');
      let sum = 0;
      sizes.forEach((size, index) => {
        if (index !== 2) {
          sum += parseInt(size);
        } else {
          sum += parseInt(size) * 4;
        }
      });
      return sum;
    },
    getTimeFromDatetime(datetime) {
      return datetime ? dayjs(datetime).format('HH:mm:ss') : '-';
    },
    dashboardFilterTable(row, data) {
      if (row.hole && row.hole.includes(data.toLowerCase())) {
        return true;
      } else if (row['game_id'] && row['game_id'].includes(data.toLowerCase())) {
        return true;
      } else if (
        row['game_id'] &&
                this.activeGamesOverview.totalMembers[row['game_id']].toString().includes(data.toLowerCase())
      ) {
        return true;
      } else if (row['scored_datetime'] && this.getTimeFromDatetime(row['scored_datetime']).includes(data.toLowerCase())) {
        return true;
      } else if (row.name && row.name.toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (row.timeFrom && row.timeFrom.toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (row.activityName && row.activityName.toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (row.teamSize && row.teamSize.toLowerCase().includes(data.toLowerCase())) {
        return true;
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (this.evenHoles.includes(item.hole)) {
        return 'row-highlight';
      }
    },
  },
};
</script>