export const activeFlightsTableFields = [
  { key: 'hole', label: 'Bahn', class: 'text-center' },
  { key: 'game_id', label: 'Game ID', class: 'text-center' },
  { key: 'teamSize', label: 'Personen', class: 'text-center' },
  { key: 'scored_datetime', label: 'Starzeit', class: 'text-center' },
];

export const todayBookingTableFields = [
  { key: 'name', label: 'Name' },
  { key: 'timeFrom', label: 'Zeit', class: 'text-center' },
  { key: 'activityName', label: 'Type' },
  { key: 'teamSize', label: 'Personen', class: 'text-center' },
];